import { VDataTable } from "vuetify/lib";

export default {
  name: "DataTable",
  extends: VDataTable,
  props: {
    ["footer-props"]: {
      type: Object,
      default: () => ({
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: "mdi-chevron-double-left",
        lastIcon: "mdi-chevron-double-right",
        prevIcon: "mdi-chevron-left",
        nextIcon: "mdi-chevron-right",
        itemsPerPageOptions: [25, 50, 100, -1]
      })
    }
  },
  methods: {
    genContent() {
      return this.$createElement("div");
    }
  }
};
